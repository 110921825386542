import createFilter from "./CreateEstimateFilter";

export default estimate => {
  const filtered = createFilter(estimate);
  delete filtered.project;

  filtered.id = estimate.id;

  return filtered;
};
