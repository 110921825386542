<template lang="pug">
  estimate-form(
    v-model='estimate',
    :loading='$apollo.loading',
    :saving='saving',
    submit-label='Update estimate',
    @save='updateEstimate',
    @cancel='cancelUpdate'
  )
</template>

<script>
import Estimate from "@/graphql/queries/finance/Estimate.gql";
import clone from "ramda/src/clone";
import updateEstimateMutation from "@/graphql/mutations/finance/estimates/UpdateEstimate.gql";
import updateEstimateFilter from "@/graphql/mutations/filters/UpdateEstimateFilter";
import mutate from "@/components/utils/Mutator";
import EstimateForm from "./Form.vue";

export default {
  apollo: {
    record: {
      query: Estimate,
      variables() {
        return {
          id: this.$route.params.id
        };
      },
      update(data) {
        return data.estimate;
      },
      result({ data }) {
        this.estimate = clone(data.estimate);
      }
    }
  },
  components: {
    EstimateForm
  },
  data() {
    return {
      saving: false,
      estimate: null
    };
  },
  methods: {
    cancelUpdate() {
      window.location.assign(`/estimates/${this.estimate.id}`);
    },
    updateEstimate() {
      const vm = this;
      this.saving = true;

      return mutate(this, {
        mutation: updateEstimateMutation,
        variables: {
          input: {
            ...updateEstimateFilter(this.estimate)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/estimates/${data.data.updateEstimate.estimate.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    }
  }
};
</script>
